const dungeons = {
    "ARR Leveling Dungeons": [
        "Sastasha",
        "The Tam-Tara Deepcroft",
        "Copperbell Mines",
        "Halatali",
        "The Thousand Maws of Toto-Rak",
        "Haukke Manor",
        "Brayflox's Longstop",
        "The Sunken Temple of Qarn",
        "Cutter's Cry",
        "The Stone Vigil",
        "Dzemael Darkhold",
        "The Aurum Vale",
        "The Dusk Vigil",
    ],
    "ARR Endgame Dungeons": [
        "The Wanderer's Palace",
        "Castrum Meridianum",
        "The Praetorium",
        "Amdapor Keep",
        "Pharos Sirius",
        "Copperbell Mines (Hard)",
        "Haukke Manor (Hard)",
        "The Lost City of Amdapor",
        "Halatali (Hard)",
        "Brayflox's Longstop (Hard)",
        "Hullbreaker Isle",
        "Tam-Tara Deepcroft (Hard)",
        "The Stone Vigil (Hard)",
        "Snowcloak",
        "Sastasha (Hard)",
        "The Sunken Temple of Qarn (Hard)",
        "The Keeper of the Lake",
        "The Wanderer's Palace (Hard)",
        "Amdapor Keep (Hard)"
    ],
    "Guildheists": [
        "Basic Training: Enemy Parties", 
        "Under the Armor", 
        "Basic Training: Enemy Strongholds", 
        "Hero on the Halfshell", 
        "Pulling Poison Posies", 
        "Stinging Back", 
        "All's Well that Ends in the Well", 
        "Flicking Sticks and Taking Names", 
        "More than a Feeler", 
        "Annoy the Void", 
        "Shadow and Claw", 
        "Long Live the Queen", 
        "Ward Up", 
        "Solemn Trinity"
    ],
    "ARR Alliance Raids": [
        "The Labyrinth of the Ancients",
        "Syrcus Tower",
        "The World of Darkness"
    ],
    "ARR Trials": [
        "The Bowl of Embers",
        "The Navel",
        "The Howling Eye",
        "The Porta Decumana",
        "The Chrysalis",
        "The Steps of Faith",
        "A Relic Reborn: The Chimera",
        "A Relic Reborn: The Hydra",
        "Battle on the Big Bridge",
        "The Dragon's Neck",
        "Battle in the Big Keep",
        "The Bowl of Embers (Hard)",
        "The Howling Eye (Hard)",
        "The Navel (Hard)",
        "Thornmarch (Hard)",
        "The Whorleater (Hard)",
        "The Striking Tree (Hard)",
        "Akh Afah Amphitheatre (Hard)",
        "Urth's Fount"
    ],
    "ARR Trials (Extreme)": [
        "The Minstrel's Ballad: Ultima's Bane",
        "The Howling Eye (Extreme)",
        "The Navel (Extreme)",
        "The Bowl of Embers (Extreme)",
        "Thornmarch (Extreme)",
        "The Whorleater (Extreme)",
        "The Striking Tree (Extreme)",
        "Akh Afah Amphitheatre (Extreme)"
    ],
    "HW Leveling Dungeons": [
        "The Dusk Vigil",
        "Sohm Al",
        "The Aery",
        "The Vault",
        "The Great Gubal Library"
    ],
    "HW Endgame Dungeons": [
        "The Aetherochemical Research Facility",
        "Neverreap",
        "The Fractal Continuum",
        "Saint Mocianne's Arboretum",
        "Pharos Sirius (Hard)",
        "The Antitower",
        "The Lost City of Amdapor (Hard)",
        "Sohr Khai",
        "Hullbreaker Isle (Hard)",
        "Xelphatol",
        "The Great Gubal Library (Hard)",
        "Baelsar's Wall",
        "Sohm Al (Hard)"
    ],
    "HW Normal Raids": [
        "Alexander - The Fist of the Father",
        "Alexander - The Cuff of the Father",
        "Alexander - The Arm of the Father",
        "Alexander - The Burden of the Father",
        "Alexander - The Fist of the Son",
        "Alexander - The Cuff of the Son",
        "Alexander - The Arm of the Son",
        "Alexander - The Burden of the Son",
        "Alexander - The Eyes of the Creator",
        "Alexander - The Breath of the Creator",
        "Alexander - The Heart of the Creator",
        "Alexander - The Soul of the Creator"
    ],
    "HW Alliance Raids": [
        "The Void Ark",
        "The Weeping City of Mhach",
        "Dun Scaith"
    ],
    "HW Trials": [
        "Thok ast Thok (Hard)",
        "The Limitless Blue (Hard)",
        "The Singularity Reactor",
        "The Final Steps of Faith",
        "Containment Bay S1T7",
        "Containment Bay P1T6",
        "Containment Bay Z1T9"
    ],
    "HW Trials (Extreme)": [
        "The Limitless Blue (Extreme)",
        "Thok ast Thok (Extreme)",
        "The Minstrel's Ballad: Thordan's Reign",
        "The Minstrel's Ballad: Nidhogg's Rage",
        "Containment Bay S1T7 (Extreme)",
        "Containment Bay P1T6 (Extreme)",
        "Containment Bay Z1T9 (Extreme)"
    ],
    "SB Leveling Dungeons": [
        "The Sirensong Sea",
        "Shisui of the Violet Tides",
        "Bardam's Mettle",
        "Doma Castle",
        "Castrum Abania"
    ],
    "SB Endgame Dungeons": [
        "Ala Mhigo",
        "Kugane Castle",
        "The Temple of the Fist",
        "The Drowned City of Skalla",
        "Hells' Lid",
        "The Fractal Continuum (Hard)",
        "The Swallow's Compass",
        "Saint Mocianne's Arboretum (Hard)",
        "The Burn",
        "The Ghimlyt Dark"
    ],
    "SB Normal Raids": [
        "Deltascape V1.0",
        "Deltascape V2.0",
        "Deltascape V3.0",
        "Deltascape V4.0",
        "Sigmascape V1.0",
        "Sigmascape V2.0",
        "Sigmascape V3.0",
        "Sigmascape V4.0",
        "Alphascape V1.0",
        "Alphascape V2.0",
        "Alphascape V3.0",
        "Alphascape V4.0"
    ],
    "SB Alliance Raids": [
        "The Royal City of Rabanastre",
        "The Ridorana Lighthouse",
        "The Orbonne Monastery"
    ],
    "SB Trials": [
        "The Pool of Tribute",
        "Emanation",
        "The Royal Menagerie",
        "The Jade Stoa",
        "Castrum Fluminis",
        "The Great Hunt",
        "Hells' Kier",
        "The Wreath of Snakes",
        "Kugane Ohashi"
    ],
    "SB Trials (Extreme)": [
        "The Pool of Tribute (Extreme)",
        "Emanation (Extreme)",
        "The Minstrel's Ballad: Shinryu's Domain",
        "The Jade Stoa (Extreme)",
        "The Minstrel's Ballad: Tsukuyomi's Pain",
        "The Great Hunt (Extreme)",
        "Hells' Kier (Extreme)",
        "The Wreath of Snakes (Extreme)"
    ],
    "ShB Leveling Dungeons": [
        "Holminster Switch",
        "Dohn Mheg",
        "The Qitana Ravel",
        "Malikah's Well",
        "Mt. Gulg"
    ],
    "ShB Endgame Dungeons": [
        "Amaurot",
        "The Twinning",
        "Akadaemia Anyder",
        "The Grand Cosmos",
        "Anamnesis Anyder",
        "The Heroes' Gauntlet",
        "Matoya's Relict",
        "Paglth'an"
    ],
    "ShB Normal Raids": [
        "Eden's Gate: Resurrection",
        "Eden's Gate: Descent",
        "Eden's Gate: Inundation",
        "Eden's Gate: Sepulture",
        "Eden's Verse: Fulmination",
        "Eden's Verse: Furor",
        "Eden's Verse: Iconoclasm",
        "Eden's Verse: Refulgence",
        "Eden's Promise: Umbra",
        "Eden's Promise: Litany",
        "Eden's Promise: Anamorphosis",
        "Eden's Promise: Eternity"
    ],
    "ShB Alliance Raids": [
        "The Copied Factory",
        "The Puppets' Bunker",
        "The Tower at Paradigm's Breach"
    ],
    "ShB Trials": [
        "The Dancing Plague",
        "The Crown of the Immaculate",
        "The Dying Gasp",
        "Cinder Drift",
        "The Seat of Sacrifice",
        "Castrum Marinum",
        "The Cloud Deck"
    ],
    "ShB Trials (Extreme)": [
        "The Dancing Plague (Extreme)",
        "The Crown of the Immaculate (Extreme)",
        "The Minstrel's Ballad: Hades's Elegy",
        "Cinder Drift (Extreme)",
        "Memoria Misera (Extreme)",
        "The Seat of Sacrifice (Extreme)",
        "Castrum Marinum (Extreme)",
        "The Cloud Deck (Extreme)"
    ],
    "EW Leveling Dungeons": [
        "The Tower of Zot",
        "The Tower of Babil",
        "Vanaspati",
        "Ktisis Hyperboreia",
        "The Aitiascope"
    ],
    "EW Endgame Dungeons": [
        "The Dead Ends",
        "The Stigma Dreamscape",
        "Smileton",
        "Alzadaal's Legacy",
        "The Fell Court of Troia",
        "Lapis Manalis",
        "The Aetherfont",
        "The Lunar Subterrane",
    ],
    "EW Normal Raids": [
        "Asphodelos: The First Circle",
        "Asphodelos: The Second Circle",
        "Asphodelos: The Third Circle",
        "Asphodelos: The Fourth Circle",
        "Abyssos: The Fifth Circle",
        "Abyssos: The Sixth Circle",
        "Abyssos: The Seventh Circle",
        "Abyssos: The Eighth Circle",
        "Anabaseios: The Ninth Circle",
        "Anabaseios: The Tenth Circle",
        "Anabaseios: The Eleventh Circle",
        "Anabaseios: The Twelfth Circle",
    ],
    "EW Alliance Raids": [
        "Aglaia",
        "Euphrosyne",
        "Thaleia",
    ],
    "EW Trials": [
        "The Dark Inside",
        "The Mothercrystal",
        "The Final Day",
        "Storm's Crown",
        "Mount Ordeals",
        "The Voidcast Dais",
        "The Abyssal Fracture",
    ],
    //"EW Trials (Extreme)": [],
}

export default dungeons;
export type Dungeon = Record<string, string[]>
export const dungeonTypes = {
    'Leveling Dungeons': ['ARR Leveling Dungeons', 'HW Leveling Dungeons', 'SB Leveling Dungeons', 'ShB Leveling Dungeons', 'EW Leveling Dungeons'],
    'Endgame Dungeons': ['ARR Endgame Dungeons', 'HW Endgame Dungeons', 'SB Endgame Dungeons', 'ShB Endgame Dungeons', 'EW Endgame Dungeons'],
    'Guildheists': ['Guildheists'],
    'Trials': ['ARR Trials', 'HW Trials', 'SB Trials', 'ShB Trials', 'EW Trials'],
    'Extreme Trials': ['ARR Trials (Extreme)', 'HW Trials (Extreme)', 'SB Trials (Extreme)', 'ShB Trials (Extreme)', 'EW Trials (Extreme)'],
    'Normal Raids': ['HW Normal Raids', 'SB Normal Raids', 'ShB Normal Raids', 'EW Normal Raids'],
    'Alliance Raids': ['ARR Alliance Raids', 'HW Alliance Raids', 'SB Alliance Raids', 'ShB Alliance Raids', 'EW Alliance Raids']
};

export const expansionTypes = {
    'A Realm Reborn': ['ARR Leveling Dungeons', 'ARR Endgame Dungeons', 'Guildheists', 'ARR Trials', 'ARR Trials (Extreme)', 'ARR Alliance Raids'],
    'Heavensward': ['HW Leveling Dungeons', 'HW Endgame Dungeons', 'HW Trials', 'HW Trials (Extreme)', 'HW Normal Raids', 'HW Alliance Raids'],
    'Stormblood': ['SB Leveling Dungeons', 'SB Endgame Dungeons', 'SB Trials', 'SB Trials (Extreme)', 'SB Normal Raids', 'SB Alliance Raids'],
    'Shadowbringers': ['ShB Leveling Dungeons', 'ShB Endgame Dungeons', 'ShB Trials', 'ShB Trials (Extreme)', 'ShB Normal Raids', 'ShB Alliance Raids'],
    'Endwalker': ['EW Leveling Dungeons', 'EW Endgame Dungeons', 'EW Trials', 'EW Trials (Extreme)', 'EW Normal Raids', 'EW Alliance Raids'],
};